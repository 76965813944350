import { useEffect, useRef } from "react";

export function LineKlient(params) {

   
    

   function Dvig(params) {
    const root = document.documentElement;
    const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue("--marquee-elements-displayed");
    let marqueeContent = document.querySelector("ul.marquee-content");
    
    root.style.setProperty("--marquee-elements", marqueeContent.children.length);
    for(let i=0; i<marqueeElementsDisplayed; i++) {
      marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
    }
   }
    setTimeout(Dvig,2000)
  return (
    <div className="lineKlient marquee">
      <ul className="container marquee-content">
        <li>
          <img
            src={process.env.PUBLIC_URL + "/img/lineKlient/1.png"}
            alt="Европлан"
          />
        </li>
        <li>
          <img
            src={process.env.PUBLIC_URL + "/img/lineKlient/2.png"}
            alt="РЕСО лизинг"
          />
        </li>
        <li>
          <img
            src={process.env.PUBLIC_URL + "/img/lineKlient/3.png"}
            alt="Альфа-Лизинг"
          />
        </li>
        <li>
          <img
            src={process.env.PUBLIC_URL + "/img/lineKlient/4.png"}
            alt="ВЭБ Лизинг"
          />
        </li>
        <li>
          <img
            src={process.env.PUBLIC_URL + "/img/lineKlient/5.png"}
            alt="СБЕР Лизинг"
          />
        </li>
        <li>
          <img
            src={process.env.PUBLIC_URL + "/img/lineKlient/6.png"}
            alt="CARCADE"
          />
        </li>
        <li>
          <img
            src={process.env.PUBLIC_URL + "/img/lineKlient/7.png"}
            alt="Балтийский Лизинг"
          />
        </li>
      </ul>
    </div>
  );
}
