import { NewsElement } from "../bloc/naws";
import { news } from "../data/bec";

export default function NewsAll(params) {
    document.title = 'СпецАвто-Новости';

    return    (<section id="news" className="news">
    <main className="container">
      <h1>
        Новости
         компании Спецавто
      </h1>
      <p>
      Будьте всегда в курсе событий
      </p>
      <div className="news-body">
        <div className="news-body_list">
          {
              news.map((e,i)=>(
                  <NewsElement key={i} id={i} img={e.img} date={e.data} text={e.text} />
              ))
          }
        </div>
       
      </div>
     
    </main>
  </section>)

}