

export default function Preloader(params) {
    

    return (
        <div id="preloader">
        <div id="loader"></div>
        </div>
    )
}