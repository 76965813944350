import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { todosSelector } from "../stor/selectors";

export function BreadCrumbs() {
    const link =useLocation()
    const parts =[]= link.pathname.split("/");
    const data =useSelector(todosSelector);
    function Links(params) {
        if (params.title==="") {
            return(
                <NavLink to="/" className="breadCrumbs_link">
                    <img src={process.env.PUBLIC_URL + "/img/home.png"} alt="" /> 
                </NavLink>
            )
        }
        if (params.title==="kontact") {
            return(
                <NavLink to="/kontact" className="breadCrumbs_link">
                    Контакты
                </NavLink>
            )
        }
        if (params.title==="kompani") {
            return(
                <NavLink to="/kompani" className="breadCrumbs_link">
                    О нас 
                </NavLink>
            )
        }
         if (params.title==="katalog") {
            return(
                <NavLink to="/katalog" className="breadCrumbs_link">
                    Каталог 
                </NavLink>
            )

        }else if (params.title==="uaz") {
            return(
                <NavLink to={`/katalog`} className="breadCrumbs_link">
                    УАЗ
                </NavLink>
            )
        } else  {
            return(
                <NavLink to={`/katalog/uaz/${params.title}`} className="breadCrumbs_link">
                    {data.specific.name}
                </NavLink>
            )
        }
    }
    return(
        <div className="container">
            <div className="BreadCrumbs">
            {
                parts.map((e,i)=>(
                    <Links key={i} title={e} />
                    
                ))
            }
            </div>
            
        </div>
    )
}