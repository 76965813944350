import axios from "axios";
import { allFilure, spacificSusccess } from "./creatos.js";
import { allSusccess } from "./creatos.js";
import { allStarted } from "./creatos.js";


const BASE_URL="https://specavto.agatech.ru/adminpanel/api.php"
// const BASE_URL="/adminpanel/api.php"

export const allPrev = ({ kat }) => async (dispatch) => {
    // dispatch(allStarted())

    try {
      const { data } = await axios.get(`${BASE_URL}?cat_id=${kat}`);

      dispatch(allSusccess(data));
    } catch (error) {
      // dispatch(allFilure())
    }
};
export const spacifics = ({ translit }) => async (dispatch) => {
    // dispatch(allStarted())

    try {
      const { data } = await axios.get(`${BASE_URL}/?prod=${translit }`);
      dispatch(spacificSusccess(data));
    } catch (error) {
      console.log(error);
    }
};
