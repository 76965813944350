import { useEffect } from "react";
import { Link } from "react-router-dom";


export function Error(params) {
    useEffect(() => {
        document.title = 'Страница не найдена';
      }, []);
    return(
        <section className="error">
            <div className="container ">
            <h1>
            <span className="orange">Oops!</span>
            </h1>
            <img src={process.env.PUBLIC_URL + "/img/404.png"} alt="" />
            <h2>
            Данная страница не найдена
            </h2>
            <Link className="news_button but" to="/">
            вернуться на главную
            </Link>
            </div>
        </section>
    )
}